import React,{useState,useEffect}from "react"
import {graphql ,Link } from "gatsby"
import Layout from "@components/layout"
import Meta from "@components/meta"
import BottomCta from "@components/bottom-cta"
import '@styles/pages/teachers.scss'
import ProfileCard from "@components/profile-card"
import posed, { PoseGroup } from 'react-pose'
const transition = {
  visible: { opacity: 1,delay: 600, },
  hidden: { opacity: 0}
}
const Box = posed.div(transition)


const TeachersPage = (props) => {
  const [path, setPath] = useState(false);
  useEffect(()=> {
    setPath(true);
    return ()=> setPath(false);
  })
  return (
    <Layout key={props.location.pathname}>
      <Meta
        title="講師紹介"
      />
      <PoseGroup>
        <Box key={"teachers"} pose={path ? "visible" : "hidden"}>
        <section class="page">
          <div class="page-top">
            <div class="page-top__container">
              <ol>
                <li><Link to="/">TOP&nbsp;>&nbsp;</Link></li>
                <li>講師紹介</li>
              </ol>
              <h2 class="page-top__heading">TEACHERS</h2>
              <p class="page-top__lead">講師紹介</p>
            </div>
          </div>
          <div class="section page-section">
            <div class="section__container">
              <div class="teachers-list">
                {props.data.allMicrocmsTeachers.edges.map(({ node }) => (
                  <ProfileCard 
                  imgSrc={node.image.url}
                  alt={node.name}
                  name={node.name}
                  about={`テーマ: ${node.theme}`}
                  desc={node.body}/>
                ))}
              </div>
            </div>
          </div>
        </section>
        <BottomCta/>
        </Box>
      </PoseGroup>
    </Layout>
  )
}
export default TeachersPage

export const query = graphql`
query {
  allMicrocmsTeachers {
    edges {
      node {
        body
        id
        image {
          url
        }
        name
        teachersId
        theme
      }
    }
  }
}
`
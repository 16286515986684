import React from "react"

import Image from "@components/image"
import '@styles/components/profile-card.scss'
import Img from "gatsby-image"


const ProfileCard = props => {
    
    return (
        <>
            <div class="profile-card">
                {/* <Image className="profile-card__image" filename={props.imgSrc} alt={props.alt} /> */}
                <img class="profile-card__image" src={props.imgSrc} alt={props.alt} />
                <p class="profile-card__name">{props.name}</p>
                <p class="profile-card__about">{props.about}</p>
                <p class="profile-card__desc txt-lh_175" dangerouslySetInnerHTML={{
                    __html: `${props.desc}`,
                }}>
                </p>
            </div>
        </>
    )
}
export default ProfileCard;